

$select-color-item:					map.get($colours, "mint");
$select-color-item-text:			map.get($colours, "white");
$select-color-item-active-text:		map.get($colours, "white");
$select-color-item-border:			map.get($colours, "blue");
$select-color-item-active:			map.get($colours, "tangerine");
$select-color-item-active-border:	map.get($colours, "blue");
$select-width-item-border:			1px;

$select-shadow-input:				none;
$select-shadow-input-focus:			none;


@import "../node_modules/tom-select/dist/scss/tom-select";
@include ts-caret();

.ts-hidden-accessible{
    display: none !important;
}

.#{$select-ns}-wrapper {
	display:flex;
	min-height:$select-line-height + ($select-padding-y*2) + ($select-border-width *2);

	&.multi {

		&.has-items .#{$select-ns}-control {
			$padding-x: $select-padding-x - 3px;
			padding-left: $padding-x;
			--ts-pr-min:$padding-x;
		}

		.#{$select-ns}-control {
			[data-value] {
				border-radius: 3px;
				background-color: map.get($colours, "mint");
				color: map.get($colours, "white");
                margin-right: map.get($margins, "small");
                line-height: 27px;
                padding-left: map.get($paddings, "medium");
                padding-right: 7px;
				&.active {
				    background-color: map.get($colours, "tangerine");
				}
			}
		}

		&.disabled .#{$select-ns}-control [data-value] {
			color: map.get($colours, "background");
			text-shadow: none;
			background: none;
			box-shadow: none;

			&, .remove {
				border-color: map.get($colours, "border");
			}
			.remove {
				background: none;
			}
		}

	}
	&.single {
		.#{$select-ns}-control {
			background-color: map.get($colours, "background");
		}
	}
}

.#{$select-ns}-wrapper.single .#{$select-ns}-control, .#{$select-ns}-dropdown.single {
	border-color: map.get($colours, "border");;
}

.#{$select-ns}-control {
	.dropdown-active & {
		border-radius: $select-border-radius $select-border-radius 0 0;
	}
}

.#{$select-ns}-dropdown {
	.optgroup-header {
		padding-top: $select-padding-dropdown-item-y + 2px;
		font-weight: bold;
		font-size: 0.85em;
	}
	.optgroup {
		border-top: 1px solid $select-color-dropdown-border-top;
		&:first-child {
			border-top: 0 none;
		}
	}
}


.selectize-control.multi .selectize-input>div {
	border-radius: 3px;
	background-color: map.get($colours, "mint");
	color: map.get($colours, "white");
	margin-right: map.get($margins, "small");
	line-height: 27px;
	padding-left: map.get($paddings, "medium");
	padding-right: 7px!important;
	font-family: 'EncodeSansExpanded';
	
	.remove {
		display: none;
	}
}