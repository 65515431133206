@each $name, $size in $widths {
    .uk-width-#{$name} {
        width: $size;
    }
}

@each $name, $size in $heights {
    .uk-height-#{$name} {
        height: $size;
    }
}

@each $direction in $directions {
    .sticky-#{$direction} {
        position: sticky;
        #{$direction}: 0;
    }
}

@each $value in $overflow-values {
    .overflow-#{$value} {
        overflow: #{$value};
    }

    .overflow-x-#{$value} {
        overflow-x: #{$value};
    }

    .overflow-y-#{$value} {
        overflow-y: #{$value};
    }
}

@each $display in $displays {
    .uk-#{$display} {
        display: #{$display};
    }
}

@each $name, $margin in $margins {
    .uk-margin-#{$name} {
        margin: #{$margin};
    }

    @each $direction in $directions {
        .uk-margin-#{$name}-#{$direction} {
            margin-#{$direction}: #{$margin};
        }
    }
}

.uk-margin-remove {
    margin: 0;
}

@each $direction in $directions {
    .uk-margin-remove-#{$direction} {
        margin-#{$direction}: 0;
    }
}

@each $name, $padding in $paddings {
    .uk-padding-#{$name} {
        padding: #{$padding};
    }

    @each $direction in $directions {
        .uk-padding-#{$name}-#{$direction} {
            padding-#{$direction}: #{$padding};
        }
    }
}
// ** Had to be moved to end of main **
// .uk-padding-remove {
//     padding: 0!important;
// }

@each $direction in $directions {
    .uk-padding-remove-#{$direction} {
        padding-#{$direction}: 0;
    }
}

@each $direction in $text-directions {
    .uk-text-#{$direction} {
        text-align: #{$direction};
    }

    .uk-align-#{$direction} {
        align-items: #{$direction};
    }

    .uk-flex-#{$direction} {
        justify-content: #{$direction};
    }
}

@each $transform in $text-transforms {
    .text-transform-#{$transform} {
        text-transform: #{$transform};
    }
}

@each $name, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
        @each $sizeName, $size in $widths {
            .uk-width-#{$sizeName}\@#{$name} {
                width: $size;
            }
        }
        
        @each $sizeName, $size in $heights {
            .uk-height-#{$sizeName}\@#{$name} {
                height: $size;
            }
        }

        @each $sizeName, $size in $heights {
            .uk-height-#{$sizeName}\@#{$name} {
                height: $size;
            }
        }

        @each $marginName, $margin in $margins {
            .uk-margin-#{$marginName}\@#{$name} {
                margin: #{$margin};
            }
        
            @each $direction in $directions {
                .uk-margin-#{$marginName}-#{$direction}\@#{$name} {
                    margin-#{$direction}: #{$margin};
                }

                .uk-margin-remove-#{$direction}\@#{$name} {
                    margin-#{$direction}: 0;
                }
            }
        }
    }
}

.flex-wrap {
    flex-wrap: wrap;
}