$widths: (
    "small": 150px,
    "medium": 300px,
    "large": 450px,
    "xlarge": 600px,
    "2xlarge": 750px,
    "viewport": 100vw,
    "1-1": 100%,
    "1-3": calc(100% * 1 / 3.001),
    "2-3": calc(100% * 2 / 3.001),
    "1-4": 25%,
    "3-4": 75%,
    "1-5": 20%,
    "2-5": 40%,
    "3-5": 60%,
    "4-5": 80%,
    "1-6": calc(100% * 1 / 6.001),
    "2-6": 33.33%,
    "4-6": 66.66%,
    "5-6": calc(100% * 5 / 6.001),
    "auto": auto
);
$heights: (
    "small": 150px,
    "medium": 300px,
    "large": 450px,
    "xlarge": 600px,
    "2xlarge": 750px,
    "viewport": 100vh,
    "1-1": 100%,
    "auto": auto
);
$directions: left, right, top, bottom;
$overflow-values: auto, scroll;
$displays: flex, block, inline-block, none;
$box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
$margins: (
    "small": 5px,
    "medium": 10px,
    "large": 20px,
    "xlarge": 40px,
    "2xlarge": 80px,
    "auto": auto
);
$paddings: (
    "small": 5px,
    "medium": 10px,
    "large": 20px,
    "xlarge": 40px,
    "2xlarge": 80px
);
$colours: (
    "white": #FFF,
    "background": #F0F0F0,
    "border": #DCDEE2,
    "blue": #243782,
    "anthracite": #282b34,
    "tangerine": #e42313,
    "mint": #006e6a,
);
$font-sizes: (
    "small": 12px,
    "medium": 16px,
    "large": 20px,
    "xlarge": 24px,
    "2xlarge": 32px
);
$headings-sizes: (
    "h1": 48px,
    "h2": 36px,
    "h3": 32px,
    "h4": 24px,
    "h5": 16px,
    "h6": 12px
);
$breakpoints : (
    "small": 640px,
    "medium": 960px,
    "large": 1200px,
    "xlarge": 1600px
);
$text-directions: left, right, center, justify;
$container-width: 1600px;
$text-transforms: capitalize, uppercase, lowercase, none;