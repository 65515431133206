@use "sass:map";
@use "sass:color";

@import "fonts";
@import "variables";
@import "utility";
@import "tom-select";

* {
    box-sizing: border-box;
}

p,
li,
a,
span,
input,
textarea,
select,
button,
label,
div {
    font-family: 'EncodeSansExpanded';
    color: map.get($colours, "anthracite");
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
.cl_wrap h1,
.cl_wrap h2,
.cl_wrap h3,
.cl_wrap h4,
.cl_wrap h5,
.cl_wrap h6 {
    font-family: 'EncodeSansExpanded';
    color: map.get($colours, "blue");
    font-weight: 700!important;
}

.uk-link {
    color: map.get($colours, "mint"); 
}

.uk-tooltip, .uk-tooltip * {
    color: map.get($colours, "white")!important; 
    background-color: map.get($colours, "anthracite")!important;
}

@each $name, $font-size in $headings-sizes {
    #{$name} {
        font-size: $font-size;
    }
}

a {
    color: map.get($colours, "mint");

    &:hover {
        color: map.get($colours, "tangerine");
    }

    &[disabled] {
        color: map.get($colours, "anthracite") !important;
        cursor: not-allowed;
    }
}

main {
    background-color: map.get($colours, "background")!important;
}

body {
    margin: 0;
    background-color: map.get($colours, "background");
}

.uk-nav {
    background-color: map.get($colours, "white");
    box-shadow: $box-shadow;

    @media only screen and (max-width: 1140px){
        background-color: transparent;
    }

    ul {
        margin: 0;
        padding: map.get($paddings, "large");
        padding-top: map.get($paddings, "medium");

        li {
            display: block;
            font-size: map.get($font-sizes, "medium");

            &:not(:first-child) {
                padding-top: map.get($paddings, "small");
            }

            &:not(:last-child) {
                padding-bottom: map.get($paddings, "small");
            }

            >a,
            >span {
                padding: map.get($paddings, "medium");
                display: block;
            }

            a {
                color: map.get($colours, "anthracite");

                &:hover {
                    color: map.get($colours, "mint");
                }
            }
        }
    }

    >ul {
        padding: map.get($paddings, "xlarge");
    }
}

.uk-navbar {
    display: flex;
    background-color: map.get($colours, "anthracite")!important;
    height: 110px!important;

    .uk-navbar-left {
        .uk-logo {
            padding: map.get($paddings, "large");
            min-width: 242px;
    
            img {
                width: 100%;
            }
        }
    }

    .uk-navbar-nav {
        display: flex;
        width: 100%;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            border-right: 1px solid map.get($colours, "white");
            border-top-right-radius: map.get($font-sizes, "small");
            transition: all .2s ease-in;
            padding: map.get($paddings, "medium") map.get($paddings, "large");
            
            a {
                text-decoration: none;
                color: map.get($colours, "background");
                font-size: map.get($font-sizes, "small");
                line-height: 95px;
                padding: 0;

                &:hover {
                    color: map.get($colours, "white");
                    text-shadow: 0px 0px 30px map.get($colours, "white");
                }
            }

        }
    }

    .back {
        a {
            color: map.get($colours, "background");
            display: flex;
            line-height: 42px;
            margin: 36px map.get($margins, "large");
            text-decoration: none;

            img {
                background-color: map.get($colours, "mint");
                border-radius: 50%;
                padding: map.get($paddings, "medium");
                margin-right: map.get($margins, "medium");
            }

            &:hover {
                color: map.get($colours, "white");
            }
        }
    }
}

.navbar-bar {
    width: 100%;
    height: 15px;
    background-color: map.get($colours, "mint");
}

.bread-box {
    padding: 20px;
}

.uk-breadcrumbs, .bread-box {
    background-color: map.get($colours, "blue");
    color: map.get($colours, "white");
    font-size: map.get($font-sizes, "small");
    list-style-type: none;

    li::before {
        margin: 0!important;
    }

    li a, 
    li span {
        color: map.get($colours, "white");
        margin: 0 map.get($margins, "medium");
        text-decoration: none;
    }

    li:first-of-type a {
        margin-left: 0;
    }

    li span {
        margin-right: 0;
    }
}

.uk-container {
    max-width: $container-width;
    padding: 0 map.get($paddings, "xlarge");
    margin: 0 auto;
}

@each $name, $font-size in $font-sizes {
    .font-#{$name} {
        font-size: #{$font-size};
    }
}

@each $name, $colour in $colours {
    .uk-text-#{$name} {
        color: #{$colour}!important;
    }
}

@each $name, $colour in $colours {
    .background-#{$name} {
        background-color: #{$colour}!important;
    }
}

.uk-subnav, .uk-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -20px;
    padding: 0;
    list-style: none;
    > * {
       flex: none;
        padding-left: 20px;
        position: relative;
        >:first-child {
            padding: 5px 10px;
            background: transparent;
            color: #999;
        }
        
    }
}

.uk-subnav>.uk-active>a, .uk-tab>.uk-active>a {
    appearance: none;
    color: map.get($colours, "white") !important;
    display: inline-block !important;
    border: none;
    text-transform: uppercase;
    font-size: .875rem;
    vertical-align: bottom;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    background-color: map.get($colours, "mint");
    small {
        color: #FFF !important;
    }
}

.uk-button {
    appearance: none;
    color: map.get($colours, "white") !important;
    display: inline-block !important;
    border: none;
    text-transform: uppercase;
    font-size: map.get($font-sizes, "medium");
    padding: map.get($paddings, "medium") map.get($paddings, "large") !important;
    vertical-align: bottom;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    line-height: 1;
    font-weight: 400;

    &:hover {
        color: map.get($colours, "background");
    }

    // &:not(button) {
    //     height: 52px;
    //     line-height: 32px;
    // }

    &.button-blue {
        background-color: map.get($colours, "blue");
    }

    &.button-anthracite {
        background-color: map.get($colours, "anthracite");
    }

    &.button-mint {
        background-color: map.get($colours, "mint");
    }

    &.button-tangerine {
        background-color: map.get($colours, "tangerine");
    }

    &.button-small {
        font-size: map.get($font-sizes, "small");
        padding: map.get($paddings, "small") map.get($paddings, "medium") !important;

        // &:not(button) {
        //     height: 47px;
        //     line-height: 16px;
        // }
    }

    &.button-large {
        font-size: map.get($font-sizes, "large");
        padding: map.get($paddings, "large") map.get($paddings, "xlarge") !important;

        // &:not(button) {
        //     height: 57px;
        //     line-height: 24px;
        // }
    }

    &.uk-button-text {
        padding: 0!important;
        color: map.get($colours, "anthracite")!important;
        text-transform: capitalize!important;
        
        &:hover {
            text-decoration: underline!important;
        }
    }

    &.button-input-match {
        height: 44px;
    }
}

.button-hover {
    color: #999!important; 
    background: none!important;
    &:hover {
        color: #282b34!important;
    }
}

.uk-badge {
    border-radius: 5px!important;
    text-transform: uppercase!important;
    font-size: map.get($font-sizes, "medium")!important;
    padding: map.get($font-sizes, "medium")!important;
    font-weight: 700!important;
    display: inline-block!important;
    color: map.get($colours, "white")!important;

    &.badge-blue {
        background-color: map.get($colours, "blue")!important;
    }

    &.badge-anthracite {
        background-color: map.get($colours, "anthracite")!important;
    }

    &.badge-mint {
        background-color: map.get($colours, "mint")!important;
    }

    &.badge-tangerine {
        background-color: map.get($colours, "tangerine")!important;
    }

}

.uk-checkbox, .uk-radio {
    &:focus {
        outline: 0;
        border-color: map.get($colours, "mint")!important;
    }
}

input.uk-input,
select.uk-select,
.uk-textarea,
.uk-input input {
    background: map.get($colours, "white")!important;
    color: map.get($colours, "anthracite")!important;
    border: 1px solid map.get($colours, "border")!important;
    transition: .2s ease-in-out!important;
    transition-property: border!important;
    font-size: map.get($font-sizes, "medium")!important;

    &:focus {
        outline: 0;
        border-color: map.get($colours, "mint")!important;
    }
}

input.uk-input,
.uk-textarea,
select.uk-select {
    height: 44px!important;
    vertical-align: middle!important;
    display: inline-block!important;
    width: fill-available!important;
    padding: map.get($paddings, "small") map.get($paddings, "large")!important;
    appearance: none!important;

    &.input-small, &.uk-form-small {
        height: 34px!important;
        padding: map.get($paddings, "small") map.get($paddings, "large")!important;
        font-size: map.get($font-sizes, "small")!important;
    }

    &.input-large {
        height: 54px!important;
        line-height: 54px!important;
        font-size: map.get($paddings, "large")!important;
        padding: map.get($font-sizes, "medium") map.get($paddings, "large")!important;
    }

    &:disabled {
        background: map.get($colours, "background")!important;
    }
}

.uk-textarea {
    height: unset!important;
}

select.uk-input {
    height: 56px!important;

    &.input-small {
        height: 46px!important;
    }

    &.input-large {
        height: 88px!important;
    }
}

label.uk-input {
    input {
        width: 16px!important;
        height: 16px!important;
    }

    &.input-small {
        input {
            width: 12px!important;
            height: 12px!important;
        }

        font-size: map.get($font-sizes, "small")!important;
    }

    &.input-large {
        input {
            width: 20px!important;
            height: 20px!important;
        }

        font-size: map.get($font-sizes, "large")!important;
    }
}

label {
    margin-bottom: map.get($margins, "small");
    display: inline-block;
}

.notification {
    width: 100%;
    padding: map.get($paddings, "large") 0;
    background-color: map.get($colours, "tangerine");
    text-align: center;
    position: relative;
    transition-property: scale;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    transform-origin: top center;

    span {
        text-transform: uppercase;
        font-size: map.get($font-sizes, "medium");
        color: map.get($colours, "white");
        font-weight: 500;
        vertical-align: super;
    }

    img {
        margin-right: 20px;
        height: 30px;
    }

    .close {
        appearance: none;
        background: transparent;
        border: none;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        cursor: pointer;
        color: map.get($colours, "white");
    }
}

.uk-card {
    background-color: map.get($colours, "white");
    box-shadow: $box-shadow;
    padding: map.get($paddings, "large");
    margin: map.get($margins, "medium") 0;

    .uk-card-title {
        margin-top: 0;
        margin-bottom: map.get($margins, "large");
        font-weight: 700;
        color: map.get($colours, "blue");
    }
}

.uk-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, th b, th strong {
        text-align: left;
        color: map.get($colours, "blue");
        vertical-align: bottom;
        border-left: none!important;
        border-right: none!important;

        &.highlight-color {
            color: map.get($colours, "white");
        }
    }

    td,
    th {
        padding: map.get($paddings, "large") map.get($paddings, "medium")!important;
        font-size: initial!important;
        font-weight: 700;
        text-transform: initial;
        border-right: none;
        border-left: none;
    }

    &.report {

        th {
            color: map.get($colours, "blue")!important;
            &.highlight-color {
                color: map.get($colours, "white")!important;
            }
        }

        td {
            border-right: none!important;
            border-left: none!important;
            color: map.get($colours, "anthracite")!important;
            strong, b {
                color: map.get($colours, "anthracite")!important;
            }
            &.background-success {
                color: map.get($colours, "white")!important;
            }
        }
    }

    &.uk-table-hover tr:not(.highlight):not(.total):not(.head):hover {
        background-color: map.get($colours, "blue")!important;

        td,
        a {
            color: #fff;
        }
    }

    &.uk-table-small th, &.uk-table-small td {
        padding: map.get($paddings, "medium") !important;
        font-size: map.get($font-sizes, "small") !important;
    }

    td.uk-padding-remove, th.padding-remove {
        padding: 0!important;
    }

    thead {

        tr {
            background: none!important;
        }

        td,
        th {
            border-bottom: 1px solid map.get($colours, "border");
        }
    }

    tbody {
        tr {
            border: none!important;

            &:last-of-type {
                border-bottom: none!important;
            }
        }

        tr:not(:last-of-type) {

            td,
            th {
                border-bottom: 1px solid map.get($colours, "border");
            }
        }
    }

    tr.highlight, tr.total {
        background-color: map.get($colours, "anthracite")!important;
        color: map.get($colours, "white");

        th, td {
            color: map.get($colours, "white")!important;
        }
    }
}

.dropdown {
    display: none;
    scale: 0;
    transition-property: scale;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    transform-origin: top left;

    &.active {
        display: block;
    }
}

.dropdown-link {
    display: flex !important;
    justify-content: space-between;
    cursor: pointer;

    &.active {
        background-color: map.get($colours, "mint");
        color: map.get($colours, "white");

        &:hover {
            color: map.get($colours, "background");
        }
    }
}

.cards-nav {

    >a, >div>a {
        width: calc(33% - (((map.get($margins, "large") * 4) / 3) + 1px));
        margin: map.get($margins, "medium") map.get($margins, "large");
        min-height: map.get($heights, "medium");
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-size: cover;
        background-position: center;
        border-radius: 25px;
        text-decoration: none;

        &:first-child, &:nth-child(3n + 1) {
            margin-left: 0;
        }

        &:nth-child(3n) {
            margin-right: 0;
        }

        >div {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            height: 300px;
            border-radius: 25px;
            transition-property: background-color;
            transition-duration: 0.3s;
            transition-timing-function: linear;

            h4, p {
                color: map.get($colours, "white")!important;
            }
            
            h4 {
                margin-top: 0;
                margin-bottom: map.get($margins, "large");
            }

            p {
                margin: 0 map.get($margins, "xlarge");
            }

            img:not(.x-small-trophy, .small-trophy, .medium-trophy, .large-trophy) {
                margin-left: map.get($margins, "large");
                border-radius: 50%;
                padding: map.get($paddings, "medium");
                vertical-align: sub;
                height: 38px;
                transition-property: background-color;
                transition-duration: 0.3s;
                transition-timing-function: linear;
            }
            
            &.trophy-container .x-small-trophy {
                width: 50px;
                height: 70px;
                background: none!important;
            }

            &.trophy-container .small-trophy {
                width: 60px;
                height: 80px;
                background: none!important;
            }

            &.trophy-container .medium-trophy {
                width: 70px;
                height: 90px;
                background: none!important;
            }

            &.trophy-container .large-trophy {
                width: 80px;
                height: 100px;
                background: none!important;
            }

        }

        &:nth-child(odd) {
            >div {
                background-color: map.get($colours, "mint");

                &:hover {
                    background-color: color.scale(map.get($colours, "mint"), $alpha: -25%);

                    img {
                        background-color: map.get($colours, "blue");
                    }

                    .x-small-trophy, .small-trophy, .medium-trophy, .large-trophy {
                        background-color: none;
                    }
        
                }
        
                img {
                    background-color: map.get($colours, "anthracite");
                }
            }

            &.no-background {
                div:hover {
                    background-color: color.scale(map.get($colours, "mint"));
                }
            }
            
        }
    
        &:nth-child(even) {
            >div {
                background-color: map.get($colours, "anthracite");

                &:hover {
                    background-color: color.scale(map.get($colours, "anthracite"), $alpha: -25%);

                    img {
                        background-color: map.get($colours, "blue");
                    }
                    
                    .x-small-trophy, .small-trophy, .medium-trophy, .large-trophy {
                        background-color: none;
                    }
                }

                img {
                    background-color: map.get($colours, "mint");
                }
            }

            &.no-background {
                div:hover {
                    background-color: color.scale(map.get($colours, "anthracite"));
                }
            }
        }
    }
    >div {
        
        width: calc(33% - (((map.get($margins, "large") * 4) / 3) + 1px));
        display: inline-block;
        margin: map.get($margins, "medium") map.get($margins, "large");

        &:first-child, &:nth-child(3n + 1) {
            margin-left: 0;
        }

        &:nth-child(3n) {
            margin-right: 0;
        }

        > a {
            width: 100%;
            margin: 0;
            display: block;
        }
        &:nth-child(odd) {
            >a>div {
                background-color: map.get($colours, "mint");

                &:hover {
                    background-color: color.scale(map.get($colours, "mint"), $alpha: -25%);

                    img {
                        background-color: map.get($colours, "blue");
                    }
                }
        
                img {
                    background-color: map.get($colours, "anthracite");
                }
            }
            a.no-background {
                div:hover {
                    background-color: color.scale(map.get($colours, "mint"));
                }
            }
        }
    
        &:nth-child(even) {
            >a>div {
                background-color: map.get($colours, "anthracite");

                &:hover {
                    background-color: color.scale(map.get($colours, "anthracite"), $alpha: -25%);

                    img {
                        background-color: map.get($colours, "blue");
                    }
                }
        
                img {
                    background-color: map.get($colours, "mint");
                }
            }
            a.no-background {
                div:hover {
                    background-color: color.scale(map.get($colours, "anthracite"));
                }
            }
        }
    }
}

.progress-bar {
    height: 50px;
    border: 1px solid map.get($colours, "border");
    position: relative;
    margin-bottom: map.get($margins, "large");

    .section, .progress {
        height: 50px;
        border-right: 1px solid map.get($colours, "border");
        position: absolute;
        left: 0;
    }

    .section {
        z-index: 1;
    }

    .progress {
        background: map.get($colours, "mint");
    }

    .labels {
        top: 50px;
        position: relative;

        span {
            position: absolute;
        }

        :first-child {
            left: 0;
        }

        :not(:first-child) {
            transform: translateX(-50%);
        }

        :last-child {
            left: 100%;
            transform: translateX(-100%);
        }
    }
}

.donut-chart {
    position: relative;
	width: map.get($widths, "small");
    height: map.get($heights, "small");
	border-radius: 100%;

    .center, .border, .portion-block .circle {
        position: absolute;
    }

    .center, .portion-block, .circle {
        border-radius: 50%;
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .center {
        background: map.get($colours, "white");
        text-align: center;
        font-size: map.get($font-sizes, "xlarge");
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: calc(map.get($widths, "small") - 30px);
        height: calc(map.get($heights, "small") - 30px);
        margin: auto;
        line-height: calc(map.get($heights, "small") - 30px);
        z-index: 2;
    }

    .border {
        width: calc(map.get($widths, "small") - 10px);
        height: calc(map.get($heights, "small") - 10px);
        margin: 5px;
        border-radius: 100%;
        border: 5px solid map.get($colours, "border");
    }

    .portion-block {
        clip: rect(0px, map.get($widths, "small"), map.get($heights, "small"), calc(map.get($widths, "small") / 2));
        z-index: 1;
    }
    
    .circle {
        background-color: map.get($colours, "mint");
        clip: rect(0px, calc(map.get($widths, "small") / 2), map.get($heights, "small"), 0px);
    }

    &.full {
        .center {
            width: calc(map.get($widths, "medium") - 20px);
            height: calc(map.get($heights, "medium") - 20px);
            line-height: calc(map.get($heights, "medium") - 20px);
        }

        .border {
            width: 100%;
            height: 100%;
            margin: 0;
            border: 10px solid map.get($colours, "border");
        }

        .donut-chart {
            width: calc(map.get($widths, "medium") - 50px);
            height: calc(map.get($heights, "medium") - 50px);
            top: 15px;
            left: 15px;

            .portion-block {
                clip: rect(0px, calc(map.get($widths, "medium") - 50px), calc(map.get($heights, "medium") - 50px), calc((map.get($widths, "medium") - 50px) / 2));
            }

            .circle {
                clip: rect(0px, calc((map.get($widths, "medium") - 50px) / 2), calc(map.get($heights, "medium") - 50px), 0px);
                background-color: map.get($colours, "tangerine");
            }

            .center {
                width: calc(map.get($widths, "medium") - 70px);
                height: calc(map.get($heights, "medium") - 70px);
                line-height: calc(map.get($heights, "medium") - 70px);
            }

            .donut-chart {
                width: calc(map.get($widths, "medium") - 100px);
                height: calc(map.get($heights, "medium") - 100px);
                top: 15px;
                left: 15px;
    
                .portion-block {
                    clip: rect(0px, calc(map.get($widths, "medium") - 100px), calc(map.get($heights, "medium") - 100px), calc((map.get($widths, "medium") - 100px) / 2));
                }
    
                .circle {
                    clip: rect(0px, calc((map.get($widths, "medium") - 100px) / 2), calc(map.get($heights, "medium") - 100px), 0px);
                    background-color: map.get($colours, "blue");
                }
    
                .center {
                    width: calc(map.get($widths, "medium") - 120px);
                    height: calc(map.get($heights, "medium") - 120px);
                    line-height: calc(map.get($heights, "medium") - 120px);
                }
            }
        }
    }
}

.donut-chart.donut-120 {
	width: 120px;
    height: 120px;

    .center {
        width: calc(120px - 30px);
        height: calc(120px - 30px);
        line-height: calc(120px - 30px);
    }

    .border {
        width: calc(120px - 10px);
        height: calc(120px - 10px);
    }

    .portion-block {
        clip: rect(0px, 120px, 120px, calc(120px / 2));
    }
    
    .circle {
        clip: rect(0px, calc(120px / 2), 120px, 0px);
    }
}

.donut-box {
    position: relative;
	border-radius: 100%;

    .chart-donut-track {
        fill: transparent;
        stroke: rgba(map.get($colours, "border"),1);
        stroke-width: 1.75;
        width: calc(map.get($widths, "medium"));
        height: calc(map.get($heights, "medium"));
        display: block;
    }

    .chart-donut-bar {
        display: block;
        width: calc(map.get($widths, "medium"));
        height: calc(map.get($heights, "medium"));
        opacity: 0;
        fill: transparent;
        stroke-width: 8;
        stroke-linecap: square;
        transform: rotate(90deg) scaleX(-1);
        border-radius: 0;
        transform-origin: 50%;
        stroke: map.get($colours, "mint")!important;
    }

    .chart-donut-label {
        color: map.get($colours, "anthracite")!important;
        opacity: 1;
        font-size: 70%;
    }
}

.legend {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-left: map.get($margins, "xlarge");

    div:not(:last-child) {
        margin-bottom: map.get($margins, "large");
    }
}

.mobile-menu {
    display: none;
}

.mobile-links {
    background: map.get($colours, "anthracite");
    height: 100vh;
    display: none;
    width: 0;
    z-index: 1;
    flex-direction: column;
    transition-property: width;
    transition-duration: 0.3s;
    position: absolute;
    right: 0;
    top: 0;

    .close {
        appearance: none;
        border: none;
        background: transparent;
        color: map.get($colours, "white");
        font-size: 50px;
        text-align: right;
        margin-top: 25px;
        margin-bottom: map.get($margins, "large");
        margin-right: 25px;
        margin-left: 225px;
    }

    a {
        color: map.get($colours, "white");
        padding: map.get($paddings, "medium") map.get($paddings, "large");

        &:focus {
            color: map.get($colours, "mint");
        }
    }
}

#playbook.code {
    background-color: map.get($colours, "anthracite");
    color: map.get($colours, "white");
}

@media (max-width: (map.get($breakpoints, "xlarge") - 0.1px)) {
    .cards-nav {
        >a {
            >div {
                img {
                    display: inline-block;
                    margin: 10px auto 0 auto;
                }
            }
        }
    }
}

@media (max-width: (map.get($breakpoints, "large") - 0.1px)) {
    .uk-container {
        padding: 0 map.get($paddings, "large");
    }
}

@media (max-width: (map.get($breakpoints, "medium") - 0.1px)) {
    body > div {
        flex-wrap: wrap-reverse;
    }

    #toc-wrapper {
        display: block;
        position: static;
        width: 100%;
        height: auto;
        margin: 0 map.get($margins, "large") map.get($margins, "large") map.get($margins, "large");

        h4, #table-of-contents, hr {
            display: none;
        }
    }

    #playbook {
        height: auto;
        overflow: hidden;
    }

    #playbook > .container {
        padding: 0;
    }

    .navbar {
        justify-content: space-between;
        
        .mobile-menu {
            display: inline-block;
            appearance: none;
            background: transparent;
            border: none;
            color: map.get($colours, "white");
            padding: 0;
            margin-right: map.get($margins, "large");

            img {
                width: 64px;
            }
        }
        
        .links {
            display: none;
        }
    }
}

@media (max-width: (map.get($breakpoints, "xlarge") - 0.1px)) {
    .back {
        display: none;
    }
}

@media (min-width: map.get($breakpoints, "small")) and (max-width: (map.get($breakpoints, "large") - 0.1px)) {
    .cards-nav {
        >a {
            width: calc(50% - (map.get($margins, "large") + 5px));

            &:nth-child(odd) {
                margin-left: 0;
                margin-right: map.get($margins, "large");
            }

            &:nth-child(even) {
                margin-right: 0;
                margin-left: map.get($margins, "large");
            }
        }
    }

    .cards-nav {
        >div {
            width: calc(50% - (map.get($margins, "large") + 5px));

            &:nth-child(odd) {
                margin-left: 0;
                margin-right: map.get($margins, "large");
            }

            &:nth-child(even) {
                margin-right: 0;
                margin-left: map.get($margins, "large");
            }
        }
    }
}

@media (max-width: (map.get($breakpoints, "small") - 0.1px)) {
    .cards-nav {
        >a {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .notification {
        img {
            margin: 0;
        }

        .close {
            position: static;
            transform: none;
            vertical-align: top;
        }
    }

    .legend {
        margin-left: 0;
        margin-top: map.get($margins, "large");
        text-align: center;
    }
}


@media (max-width: 400px) {
    .donut-chart {
        width: calc(map.get($widths, "medium") - 50px);
        height: calc(map.get($heights, "medium") - 50px);
    
        .center {
            width: calc(map.get($widths, "medium") - 100px);
            height: calc(map.get($heights, "medium") - 100px);
            line-height: calc(map.get($heights, "medium") - 100px);
        }
    
        .border {
            width: calc(map.get($widths, "medium") - 75px);
            height: calc(map.get($heights, "medium") - 75px);
        }
    
        .portion-block {
            clip: rect(0px, calc(map.get($widths, "medium") - 50px), calc(map.get($heights, "medium") - 50px), calc((map.get($widths, "medium") - 50px) / 2));
        }
        
        .circle {
            clip: rect(0px, calc((map.get($widths, "medium") - 50px) / 2), calc(map.get($heights, "medium") - 50px), 0px);
        }
    
        &.full {
            .center {
                width: calc(map.get($widths, "medium") - 70px);
                height: calc(map.get($heights, "medium") - 70px);
                line-height: calc(map.get($heights, "medium") - 70px);
            }
    
            .donut-chart {
                width: calc(map.get($widths, "medium") - 100px);
                height: calc(map.get($heights, "medium") - 100px);
    
                .portion-block {
                    clip: rect(0px, calc(map.get($widths, "medium") - 100px), calc(map.get($heights, "medium") - 100px), calc((map.get($widths, "medium") - 100px) / 2));
                }
    
                .circle {
                    clip: rect(0px, calc((map.get($widths, "medium") - 100px) / 2), calc(map.get($heights, "medium") - 100px), 0px);
                }
    
                .center {
                    width: calc(map.get($widths, "medium") - 120px);
                    height: calc(map.get($heights, "medium") - 120px);
                    line-height: calc(map.get($heights, "medium") - 120px);
                }
    
                .donut-chart {
                    width: calc(map.get($widths, "medium") - 150px);
                    height: calc(map.get($heights, "medium") - 150px);
        
                    .portion-block {
                        clip: rect(0px, calc(map.get($widths, "medium") - 150px), calc(map.get($heights, "medium") - 150px), calc((map.get($widths, "medium") - 150px) / 2));
                    }
        
                    .circle {
                        clip: rect(0px, calc((map.get($widths, "medium") - 150px) / 2), calc(map.get($heights, "medium") - 150px), 0px);
                    }
        
                    .center {
                        width: calc(map.get($widths, "medium") - 170px);
                        height: calc(map.get($heights, "medium") - 170px);
                        line-height: calc(map.get($heights, "medium") - 170px);
                    }
                }
            }
        }
    }
}

@media (max-width: 330px) {
    .donut-chart {
        width: calc(map.get($widths, "medium") - 75px);
        height: calc(map.get($heights, "medium") - 75px);
    
        .center {
            width: calc(map.get($widths, "medium") - 125px);
            height: calc(map.get($heights, "medium") - 125px);
            line-height: calc(map.get($heights, "medium") - 125px);
        }
    
        .border {
            width: calc(map.get($widths, "medium") - 100px);
            height: calc(map.get($heights, "medium") - 100px);
        }
    
        .portion-block {
            clip: rect(0px, calc(map.get($widths, "medium") - 75px), calc(map.get($heights, "medium") - 75px), calc((map.get($widths, "medium") - 75px) / 2));
        }
        
        .circle {
            clip: rect(0px, calc((map.get($widths, "medium") - 75px) / 2), calc(map.get($heights, "medium") - 75px), 0px);
        }
    
        &.full {
            .center {
                width: calc(map.get($widths, "medium") - 95px);
                height: calc(map.get($heights, "medium") - 95px);
                line-height: calc(map.get($heights, "medium") - 95px);
            }
    
            .donut-chart {
                width: calc(map.get($widths, "medium") - 125px);
                height: calc(map.get($heights, "medium") - 125px);
    
                .portion-block {
                    clip: rect(0px, calc(map.get($widths, "medium") - 125px), calc(map.get($heights, "medium") - 125px), calc((map.get($widths, "medium") - 125px) / 2));
                }
    
                .circle {
                    clip: rect(0px, calc((map.get($widths, "medium") - 125px) / 2), calc(map.get($heights, "medium") - 125px), 0px);
                }
    
                .center {
                    width: calc(map.get($widths, "medium") - 145px);
                    height: calc(map.get($heights, "medium") - 145px);
                    line-height: calc(map.get($heights, "medium") - 145px);
                }
    
                .donut-chart {
                    width: calc(map.get($widths, "medium") - 175px);
                    height: calc(map.get($heights, "medium") - 175px);
        
                    .portion-block {
                        clip: rect(0px, calc(map.get($widths, "medium") - 175px), calc(map.get($heights, "medium") - 175px), calc((map.get($widths, "medium") - 175px) / 2));
                    }
        
                    .circle {
                        clip: rect(0px, calc((map.get($widths, "medium") - 175px) / 2), calc(map.get($heights, "medium") - 175px), 0px);
                    }
        
                    .center {
                        width: calc(map.get($widths, "medium") - 195px);
                        height: calc(map.get($heights, "medium") - 195px);
                        line-height: calc(map.get($heights, "medium") - 195px);
                    }
                }
            }
        }
    }
}


.uk-padding-remove {
    padding: 0!important;
}

.theme-success, .uk-text-success {
    color: map.get($colours, "mint")!important;
}

.theme-danger, .uk-text-danger {
    color: map.get($colours, "tangerine")!important;
}

.theme-warning, .uk-text-warning {
    color: #ef7d00 !important;
}

.uk-badge-danger {
    color: white!important;
}

.background-success {
    background-color: map.get($colours, "mint")!important;
    border-left: 1px solid #DCDEE3!important;
    border: 1px solid #DCDEE3!important;
}

.background-danger {
    background-color: map.get($colours, "tangerine")!important;
    border-left: 1px solid #DCDEE3!important;
    border: 1px solid #DCDEE3!important;
}


.select2-container--default .select2-selection--single {
    border: 1px solid #dcdee2!important;
    height: 34px!important;
}

.uk-icon-link:hover {
    color: map.get($colours, "tangerine");
}

.uk-icon:hover {
    color: inherit!important;
}

.uk-pagination > li {
    a {
        color: #666!important;
    }
    &.uk-active {
        color: map.get($colours, "anthracite")!important;
    }
}

table.report .quartile-4 {
    background: map.get($colours, "mint")!important;
    color: map.get($colours, "background")!important;
    border-right: 1px solid #DCDEE3!important;
}

table.report .quartile-3 {
    background: #ef7d00;
    color: map.get($colours, "background")!important;
    border-right: 1px solid #DCDEE3!important;
}

table.report .quartile-2 {
    background: rgb(233, 78, 36);
    color: map.get($colours, "background")!important;
    border-right: 1px solid #DCDEE3!important;
}

table.report .quartile-1 {
    background: map.get($colours, "tangerine")!important;
    color: map.get($colours, "background")!important;
    border-right: 1px solid #DCDEE3!important;
}

.light-quartile-1 {
    background: map.get($colours, "mint")!important;
    fill: map.get($colours, "mint")!important;
}

.light-quartile-2 {
    background: rgb(233, 78, 36);
    fill: rgb(233, 78, 36);
}

.light-quartile-3 {
    background: rgb(239, 125, 0);
    fill: rgb(239, 125, 0);
}

.light-quartile-4 {
    background:map.get($colours, "tangerine")!important;
    fill: map.get($colours, "tangerine")!important;
}

.Workforce-Verified {
    background: map.get($colours, "blue")!important;
}

.uk-checkbox:checked, .uk-radio:checked {
    background-color: map.get($colours, "blue")!important;
    // background-image: url('/_/img/form-checkbox-white.svg')!important;
}


.select2-results {
    background: map.get($colours, "white")!important;
}

.select2-search__field {
    border: none!important;
}

.select2-results__option--highlighted {
    background: map.get($colours, "mint")!important;
}

.module-complete {
    background: map.get($colours, "mint")!important;
    border: 1px solid map.get($colours, "border")!important;
}

.module-incomplete {
    border: 1px solid #dcdee2!important;
    background: map.get($colours, "white")!important;
}

.cl_wrap .course_wrap .header a {
    background: map.get($colours, "white")!important;
    border: 1px solid map.get($colours, "blue")!important;
    color: map.get($colours, "blue")!important;
    font-weight: 900;
    text-transform: uppercase;
}

.uk-navbar .uk-navbar-nav li a {
    font-weight: 400 !important;
    font-family: "EncodeSansExpanded" !important;
    text-transform: none !important;
    font-size: map.get($font-sizes, "small") !important;
}

.uk-navbar .uk-navbar-nav > li > a {
    color: map.get($colours, "white") !important;
}

.uk-navbar-dropdown-nav, .uk-dropdown-nav {
    box-shadow: none !important;

    li {
        padding: 0 !important;

        a {
            color: map.get($colours, "anthracite") !important;
            line-height: 1 !important;
            padding: 5px 0 !important;
            font-size: map.get($font-sizes, "small") !important;
        }
    }
}