@font-face {
    font-family: 'EncodeSansExpanded';
    font-style: normal;
    font-weight: 100;
    src: url('/_/fonts/EncodeSans_Expanded-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'EncodeSansExpanded';
    font-style: normal;
    font-weight: 200;
    src: url('/_/fonts/EncodeSans_Expanded-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'EncodeSansExpanded';
    font-style: normal;
    font-weight: 300;
    src: url('/_/fonts/EncodeSans_Expanded-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'EncodeSansExpanded';
    font-style: normal;
    font-weight: 400;
    src: url('/_/fonts/EncodeSans_Expanded-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'EncodeSansExpanded';
    font-style: normal;
    font-weight: 500;
    src: url('/_/fonts/EncodeSans_Expanded-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'EncodeSansExpanded';
    font-style: normal;
    font-weight: 600;
    src: url('/_/fonts/EncodeSans_Expanded-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'EncodeSansExpanded';
    font-style: normal;
    font-weight: 700;
    src: url('/_/fonts/EncodeSans_Expanded-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'EncodeSansExpanded';
    font-style: normal;
    font-weight: 800;
    src: url('/_/fonts/EncodeSans_Expanded-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'EncodeSansExpanded';
    font-style: normal;
    font-weight: 900;
    src: url('/_/fonts/EncodeSans_Expanded-Black.ttf') format('truetype');
}